import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { IParams } from '../types';
import * as S from './styles';

export function DemandsWin({ altGroup, category, supplier }: IParams) {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.TitleContainer>
        <b>{t('pages.awards.managementBid.demandWin.title')}</b>
        <Divider type="vertical" />
        <span>QTY</span>
      </S.TitleContainer>
      <S.Content>Demands Win</S.Content>
    </S.Container>
  );
}
