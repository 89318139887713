import { ReactNode, useState } from 'react';
import { t } from 'i18next';
import * as S from './styles';
import { api } from '../../services/api';
import { services } from '../../services/services';
import { oneAlert } from '../../helpers/utils';
import { AwardDetailsProps } from './types';
import { CardSuppliers } from './CardBidSuplliers';
import { handleFormatValueFields } from '../../helpers/nUtils';
import { CardAwards } from './CardAwards';

export function useAwardDetails() {
  const specification = ['SO4GBIV_BR', 'SO4GBIV'];
  const [loading, setLoading] = useState(false);
  const [awardDetails, setAwardDetails] = useState<AwardDetailsProps>();
  const [isOpenCardDetails, setIsOpenDetails] = useState(true);
  const [isOpenCardAward, setIsOpenAward] = useState(true);

  const bidId = '67476832e30bfc204876eb4d';

  const handleRenderSpecs = () => {
    return specification.map((spec, index) => {
      return (
        <S.Labels className="normal" key={spec}>
          {index + 1 === specification.length ? spec : `${spec} |`}
        </S.Labels>
      );
    });
  };

  const handleFetchDetailsAward = async () => {
    try {
      setLoading(true);
      const { data, status } = await api.get(
        `${services.rfq}/award-simulation/${bidId}/split/review`
      );
      if (status === 200) {
        setAwardDetails(data);
      }
    } catch (error: any) {
      console.log(error);
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setLoading(false);
    }
  };

  const handleRenderCardsSuppliers = (): ReactNode => {
    try {
      return awardDetails?.groupDetail.suppliers.map((supplier) => {
        return (
          <CardSuppliers
            key={supplier.supplier}
            demand={
              supplier.demand
                ? handleFormatValueFields({ number: supplier.demand, notation: 'standard' })
                : '-'
            }
            supplier={supplier.supplier || '-'}
            impact={supplier.impactSavingPercentage ? `${supplier.impactSavingPercentage}%` : '-'}
            saving={
              supplier.impactSaving
                ? handleFormatValueFields({ number: supplier.impactSaving, notation: 'compact' })
                : '-'
            }
            totalWin={
              supplier.totalWin
                ? handleFormatValueFields({ number: supplier.totalWin, notation: 'compact' })
                : '-'
            }
          />
        );
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleCalculateProjectsConcludedPercent = (total: number, concluded: number): number => {
    try {
      return (concluded / total) * 100;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleRenderAwardCards = (): ReactNode => {
    try {
      return awardDetails?.cards.map((cardItem) => {
        return (
          <CardAwards
            key={cardItem.project}
            project={cardItem.project}
            remark={cardItem.remark}
            cards={cardItem.ranking}
          />
        );
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return {
    handleRenderSpecs,
    handleFetchDetailsAward,
    handleRenderCardsSuppliers,
    setIsOpenAward,
    setIsOpenDetails,
    handleRenderAwardCards,
    handleCalculateProjectsConcludedPercent,
    isOpenCardAward,
    isOpenCardDetails,
    awardDetails,
  };
}
