import { useTranslation } from 'react-i18next';
import StyledButton from '../../../components/Common/StyledButton';
import { StyledLoading } from '../../../components/Common/StyledLoading';
import { StyledPagination } from '../../../components/Common/StyledPagination';
import StyledSelect from '../../../components/Common/StyledSelect';
import SimpleTable from '../../../components/Tables/SimpleTable';
import * as S from './styles';
import { AwardPageProps } from './types';
import { useAwardList } from './useAwardList';

export function AwardListPage({ supplierView }: AwardPageProps) {
  const {
    columns,
    CMs,
    awardListData,
    isLoading,
    projectList,
    categoryList,
    altgroupList,
    qstatusList,
    supplierList,
    specificationList,
    partNumberList,
    params,
    handleUpdateFilters,
    fetchAwardListData,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    handleChangeTable,
    changePage,
  } = useAwardList();

  const { t } = useTranslation();

  return (
    <>
      <S.ContainerFilters>
        <StyledSelect
          options={CMs}
          label={t('pages.awardsSummary.awardsList.labels.cm')}
          placeholder={t('pages.awardsSummary.awardsList.placeHolder')}
          onChange={(value: string) => handleUpdateFilters('cm', value)}
          styles={{ width: '7.5rem' }}
          value={params.cm || null}
        />
        <StyledSelect
          options={projectList}
          label={t('pages.awardsSummary.awardsList.labels.project')}
          placeholder={t('pages.awardsSummary.awardsList.placeHolder')}
          onChange={(value: string) => handleUpdateFilters('project', value)}
          styles={{ width: '7.5rem' }}
          value={params.project || null}
        />
        <StyledSelect
          options={categoryList}
          label={t('pages.awardsSummary.awardsList.labels.category')}
          placeholder={t('pages.awardsSummary.awardsList.placeHolder')}
          onChange={(value: string) => handleUpdateFilters('category', value)}
          styles={{ width: '7.5rem' }}
          value={params.category || null}
        />
        <StyledSelect
          options={altgroupList}
          label={t('pages.awardsSummary.awardsList.labels.altGroup')}
          placeholder={t('pages.awardsSummary.awardsList.placeHolder')}
          onChange={(value: string) => handleUpdateFilters('altGroup', value)}
          styles={{ width: '7.5rem' }}
          value={params.altGroup || null}
        />
        <StyledSelect
          options={qstatusList}
          label={t('pages.awardsSummary.awardsList.labels.qStatus')}
          placeholder={t('pages.awardsSummary.awardsList.placeHolder')}
          onChange={(value: string) => handleUpdateFilters('qStatus', value)}
          styles={{ width: '7.5rem' }}
          value={params.qStatus || null}
        />
        {!supplierView && (
          <StyledSelect
            options={supplierList}
            label={t('pages.awardsSummary.awardsList.labels.supplier')}
            placeholder={t('pages.awardsSummary.awardsList.placeHolder')}
            onChange={(value: string) => handleUpdateFilters('supplier', value)}
            styles={{ width: '7.5rem' }}
            value={params.supplier || null}
          />
        )}
        <StyledSelect
          options={specificationList}
          label={t('pages.awardsSummary.awardsList.labels.specification')}
          placeholder={t('pages.awardsSummary.awardsList.placeHolder')}
          onChange={(value: string) => handleUpdateFilters('specification', value)}
          styles={{ width: '7.5rem' }}
          value={params.specification || null}
        />
        <StyledSelect
          options={partNumberList}
          label={t('pages.awardsSummary.awardsList.labels.partNumber')}
          placeholder={t('pages.awardsSummary.awardsList.placeHolder')}
          onChange={(value: string) => handleUpdateFilters('partNumber', value)}
          styles={{ width: '7.5rem' }}
          value={params.partNumber || null}
        />
        <StyledButton small onClick={() => fetchAwardListData()} variant="primary" text="Apply" />
      </S.ContainerFilters>
      <S.FiltersTagContainer>
        {handleRenderFiltersTags()}
        {handleRenderClearAllFilters()}
      </S.FiltersTagContainer>
      {isLoading ? (
        <StyledLoading height={10} />
      ) : (
        <>
          <SimpleTable
            pagination={false}
            columns={columns}
            dataSource={awardListData}
            rowKey="altGroup"
            scroll={{ x: 'max-content', y: 'calc(100vh - 14rem)' }}
            onChange={handleChangeTable}
          />
          <StyledPagination
            size={params.size}
            totalElements={params.totalElements}
            pageNumber={params.page + 1}
            onChange={changePage}
          />
        </>
      )}
    </>
  );
}
