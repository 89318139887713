import { ReactNode, useState } from 'react';
import { t } from 'i18next';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import { AwardSupplierRanking, CommentAward, PartNumberAward } from '../types';
import * as S from './styles';
import theme from '../../../styles/theme';
import { Demand } from '../../Finance/styles';
import { handleFormatValueFields } from '../../../helpers/nUtils';

export function useSupplierCard() {
  const [visibleModal, setVisibleModal] = useState(false);

  const handleRenderPartNumbers = (partNumbers: Array<PartNumberAward>): ReactNode => {
    try {
      return partNumbers.map((partNumber) => {
        return (
          <S.ContainerRows key={partNumber.partNumber}>
            <S.Labels className="small">{t('pages.awardSplit.awardCard.partNumber')}</S.Labels>
            <Flex gap={4}>
              <CheckCircleOutlined
                style={{ fontSize: '0.75rem', color: theme.colorsDesignSystem.primary }}
              />
              <S.Labels className="small">{partNumber.status || '-'}</S.Labels>
              <S.Labels className="smallBold">{partNumber.partNumber || '-'}</S.Labels>
            </Flex>
          </S.ContainerRows>
        );
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleValueIsPositive = (value: number) => {
    try {
      return !Number.isNaN(value) && value > 0;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleConvertCommentAwardToComment = (
    comments: Array<CommentAward>,
    data: AwardSupplierRanking
  ) => {
    return comments.map((comment) => {
      return {
        name: comment.name,
        comment: comment.comment,
        supplier: data.supplier,
        dateTime: comment.dateTime,
      };
    });
  };

  const handleCalculateTotalNetGrossBaseline = (demand: number, price: number) => {
    try {
      return demand * price;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleCalculateImpactSavingPercentage = (
    impactSaving: number,
    totalBaseline: number
  ): number => {
    try {
      return (impactSaving / totalBaseline) * 100;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleRenderFieldByCalculatedValue = <T extends keyof AwardSupplierRanking>(
    supplier: AwardSupplierRanking,
    field: T
  ): ReactNode => {
    if (field === 'totalNet') {
      const value = handleCalculateTotalNetGrossBaseline(supplier.demand, supplier.netPrice);
      return (
        <S.Labels className="middle">
          {value
            ? `$${handleFormatValueFields({
                number: value,
                notation: 'compact',
              })}`
            : '-'}
        </S.Labels>
      );
    }

    if (field === 'totalGross') {
      const value = handleCalculateTotalNetGrossBaseline(supplier.demand, supplier.grossPrice);
      return (
        <S.Labels className="normal">
          {value
            ? `$${handleFormatValueFields({
                number: value,
                notation: 'compact',
              })}`
            : '-'}
        </S.Labels>
      );
    }

    if (field === 'totalBaseline') {
      const value = handleCalculateTotalNetGrossBaseline(supplier.demand, supplier.baseline);
      return (
        <S.Labels className="smallBold">
          {value
            ? `$ ${handleFormatValueFields({
                number: value,
                notation: 'standard',
              })}`
            : '-'}
        </S.Labels>
      );
    }

    if (field === 'impactSavingPercentage') {
      const value = supplier.totalNet - supplier.totalBaseline;
      return (
        <S.Labels className="small">
          {value
            ? `${handleFormatValueFields({
                number: value,
                notation: 'standard',
              })}%`
            : '-'}
        </S.Labels>
      );
    }

    if (field === 'impactSaving') {
      const impactSaving = supplier.totalNet - supplier.totalBaseline;
      const value = handleCalculateImpactSavingPercentage(impactSaving, supplier.totalBaseline);
      return (
        <S.LabelColored color={handleValueIsPositive(value) ? 'red' : 'green'} size="strong">
          {value
            ? `$ ${handleFormatValueFields({
                number: value,
                notation: 'compact',
              })}`
            : '-'}
        </S.LabelColored>
      );
    }

    return null;
  };

  return {
    handleRenderPartNumbers,
    handleValueIsPositive,
    setVisibleModal,
    handleConvertCommentAwardToComment,
    handleCalculateTotalNetGrossBaseline,
    handleCalculateImpactSavingPercentage,
    handleRenderFieldByCalculatedValue,
    visibleModal,
  };
}
