import { Flex, Input, Progress, Radio, Select, Switch } from 'antd';
import {
  ExclamationCircleFilled,
  InfoCircleFilled,
  PercentageOutlined,
  TrophyOutlined,
  TruckOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as S from './styles';
import theme from '../../styles/theme';
import StyledSelect from '../../components/Common/StyledSelect';
import { useAwardSplit } from './useAwardSplit';
import StyledButton from '../../components/Common/StyledButton';
import { handleFormatValueFields } from '../../helpers/nUtils';
import StyledModal from '../../components/Common/StyledModal';
import { ModalTotalAwards } from './ModalTotalAwards';
import { Loading } from '../../components/Loading';

export function AwardSplit() {
  const {
    handleRenderSpecs,
    handleCalculatePercentProjectsDone,
    handleRenderAwardSupplierCards,
    setVisualizationType,
    setProjectSelected,
    handleVerifyIfProjectIsNotFirst,
    setSuppliersSelected,
    handleSaveRemark,
    handleRenderEditsSplitAward,
    handleClearAwardDistribution,
    setVisibleModal,
    handleSendAwardDistribution,
    handleGetAllData,
    handleToggleSplitDistribution,
    projectSelected,
    dataAward,
    projects,
    suppliers,
    suppliersSelected,
    errorDistribution,
    visibleModal,
    dataAwardModal,
    loading,
    propsPage,
  } = useAwardSplit();

  // const location = useLocation();
  // const { bidId, altGroup } = location.state as AwardSplitPageProps;
  const bidId = '67476832e30bfc204876eb4d';
  const altGroup = 'TESTE MEM';

  propsPage.current = { bidId, altGroup };

  useEffect(() => {
    handleGetAllData();
  }, [projectSelected]);

  return (
    <S.Container>
      {loading ? (
        <Loading />
      ) : (
        <S.ContentContainer>
          <S.ContentLeft>
            <Flex
              align="center"
              gap={8}
              style={{ fontSize: '1.75rem', color: theme.colorsDesignSystem.tagColorYellow }}
            >
              <ExclamationCircleFilled />
              <S.TitleLeftCard>{altGroup}</S.TitleLeftCard>
            </Flex>
            <hr />
            <StyledSelect
              options={projects}
              value={projectSelected}
              onChange={setProjectSelected}
            />
            <Flex vertical gap={8} justify="center">
              <S.Labels className="withBorder">{`${dataAward?.projectsConcluded}/${
                dataAward?.totalProjects
              } ${t('pages.awardSplit.labels.projects')}`}</S.Labels>
              <Progress percent={handleCalculatePercentProjectsDone()} showInfo={false} />
            </Flex>
            <S.ContainerCards>
              <S.CardLeftSide>
                <S.Labels>{t('pages.awardSplit.labels.category')}</S.Labels>
                <S.Labels className="normal">{dataAward?.category || '-'}</S.Labels>
              </S.CardLeftSide>
              <S.CardLeftSide>
                <S.Labels>{t('pages.awardSplit.labels.spec')}</S.Labels>
                <Flex gap={8}>{handleRenderSpecs()}</Flex>
              </S.CardLeftSide>
              <S.CardLeftSide>
                <S.CardFromTo>
                  <S.Labels>{t('pages.awardSplit.labels.from')}</S.Labels>
                  <S.Labels className="normal">{dataAward?.from || '-'}</S.Labels>
                  <TruckOutlined
                    style={{ fontSize: '1.2rem', color: theme.colorsDesignSystem.blue }}
                  />
                  <S.Labels>{t('pages.awardSplit.labels.to')}</S.Labels>
                  <S.Labels className="normal">{dataAward?.to || '-'}</S.Labels>
                </S.CardFromTo>
              </S.CardLeftSide>
              <S.CardLeftSide>
                <Flex justify="space-between">
                  <Flex vertical gap={4}>
                    <S.Labels>{t('pages.awardSplit.labels.startingPrice')}</S.Labels>
                    <S.Labels className="large">
                      {dataAward?.startingPrice
                        ? `$${handleFormatValueFields({
                            number: dataAward?.startingPrice,
                            notation: 'standard',
                          })}`
                        : '-'}
                    </S.Labels>
                  </Flex>
                  |
                  <Flex vertical gap={4}>
                    <S.Labels>{t('pages.awardSplit.labels.totalDemands')}</S.Labels>
                    <S.Labels className="large">
                      {handleFormatValueFields({
                        number: dataAward?.totalDemand || 0,
                        notation: 'compact',
                      }) || '-'}
                    </S.Labels>
                  </Flex>
                </Flex>
              </S.CardLeftSide>
              <StyledButton
                variant="secondary"
                onClick={() => {
                  setVisibleModal(true);
                }}
              >
                <TrophyOutlined />
                {t('pages.awardSplit.labels.projectAward')}
              </StyledButton>
              <Flex vertical gap={7}>
                <Flex gap={8}>
                  <S.Labels>{t('pages.awardSplit.labels.remarks')}</S.Labels>
                  <InfoCircleFilled style={{ color: theme.colorsDesignSystem.primary }} />
                </Flex>
                <TextArea
                  onChange={(e) => {
                    handleSaveRemark(e.target.value);
                  }}
                  value={dataAward?.remark}
                  style={{ minHeight: '4.5rem' }}
                />
              </Flex>
            </S.ContainerCards>
          </S.ContentLeft>
          <S.ContainerRight>
            <S.ContainerContentRight>
              <Flex vertical gap={8}>
                <S.Labels>{t('pages.awardSplit.labels.supplier')}</S.Labels>
                <Select
                  mode="multiple"
                  maxCount={3}
                  options={suppliers}
                  value={suppliersSelected}
                  onChange={(values) => {
                    setSuppliersSelected(values);
                    handleClearAwardDistribution();
                  }}
                />
              </Flex>
              {dataAward?.splitAward && (
                <S.ContainerCardInputs>{handleRenderEditsSplitAward()}</S.ContainerCardInputs>
              )}
              {errorDistribution && (
                <S.Labels className="alert">
                  {t('pages.awardSplit.messages.errorDistribution')}
                </S.Labels>
              )}
              <Flex justify="flex-end" align="center" gap={24}>
                <Flex align="center" gap={8}>
                  <S.Labels>{t('pages.awardSplit.labels.splitAward')}</S.Labels>
                  <Switch
                    value={dataAward?.splitAward}
                    onChange={() => {
                      handleClearAwardDistribution();
                      handleToggleSplitDistribution();
                    }}
                  />
                </Flex>
                <S.StyledRadioGroup
                  defaultValue="NET"
                  buttonStyle="solid"
                  size="small"
                  onChange={(e) => {
                    setVisualizationType(e.target.value);
                  }}
                >
                  <Radio.Button value="GROSS">{t('pages.awardSplit.labels.gross')}</Radio.Button>
                  <Radio.Button value="NET">{t('pages.awardSplit.labels.net')}</Radio.Button>
                </S.StyledRadioGroup>
              </Flex>
              <S.ContainerCardsRight>{handleRenderAwardSupplierCards()}</S.ContainerCardsRight>
            </S.ContainerContentRight>
            <S.ContainerFooter>
              <StyledButton variant="secondary">{t('pages.awardSplit.labels.cancel')}</StyledButton>
              <Flex gap={8}>
                {handleVerifyIfProjectIsNotFirst() && (
                  <StyledButton variant="secondary">
                    {t('pages.awardSplit.labels.previous')}
                  </StyledButton>
                )}
                <StyledButton onClick={handleSendAwardDistribution} variant="primary">
                  {t('pages.awardSplit.labels.continue')}
                </StyledButton>
              </Flex>
            </S.ContainerFooter>
          </S.ContainerRight>
        </S.ContentContainer>
      )}
      <StyledModal
        body={<ModalTotalAwards data={dataAwardModal} />}
        title={t('pages.awardSplit.modal.title')}
        footer={null}
        open={visibleModal}
        centered
        width="40rem"
        onClose={() => {
          setVisibleModal(false);
        }}
      />
    </S.Container>
  );
}
