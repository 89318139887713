import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AlertPage } from '../pages/Alert';
import { SKUAlertPage } from '../pages/Alert/SKUAlert';
import { Average } from '../pages/Average';
import { AVLCPage } from '../pages/AVLC';
import { RedirectLogin } from '../pages/AwaitAuth';
import { AwardDetails } from '../pages/AwardDetails';
import { AwardSplit } from '../pages/AwardSplit';
import { AwardsPage } from '../pages/AwardSummary';
import { BidResponse } from '../pages/BidResponse';
import { BidSupplierView } from '../pages/BidSupplierView';
import { BOMPage } from '../pages/BOM';
import { ComponentsPage } from '../pages/Components';
import { ComponentsGroupPage } from '../pages/ComponentsGroup';
import { ComponentsPricesPage } from '../pages/ComponentsPrices';
import { SKUDashboardPage, SpecificationsDashboardPage } from '../pages/Dashboards';
import { FileErrorsPage } from '../pages/FileErrors';
import { FinancePage } from '../pages/Finance';
import { FinanceDashboardPage } from '../pages/FinanceDashboard/Finance';
import { ProcurementDashboardPage } from '../pages/FinanceDashboard/Procurement';
import { ForecastsPage } from '../pages/Forecasts';
import { ForgotPasswordPage } from '../pages/ForgotPassword';
import { GroupsPage } from '../pages/Groups';
import { InventoryInOutPage } from '../pages/Inventory';
import { LogUsersPage } from '../pages/LogUsers';
import { NewForecastPage } from '../pages/NewForecast';
import { NewRFQ } from '../pages/NewRFQ';
import { NotificationsPage } from '../pages/Notifications';
import { PPBPage } from '../pages/PPB';
import { RulesPPBPage } from '../pages/PPB/RulesPPB';
import { PricesPage } from '../pages/Prices';
import { PricesTaxesPage } from '../pages/PricesTaxes';
import { QualificationAlert } from '../pages/QualificationAlert';
import { QuotesPage } from '../pages/QuotesPage';
import { ResetPasswordPage } from '../pages/RegisterPassword/ResetPassword';
import { ReportsScenariosPage } from '../pages/Reports/Scenarios';
import { RfqPage } from '../pages/RFQ';
import { ScenariosPage } from '../pages/Scenarios';
import { ScenariosForm } from '../pages/Scenarios/Form';
import { ScenariosSettings } from '../pages/Scenarios/Settings';
import { SettingsTaxes } from '../pages/SettingsTaxes';
import { ShareProportion } from '../pages/ShareProportion';
import { SimulationPage } from '../pages/Simulation';
import { SingleLogin } from '../pages/SingleLogin';
import { SKUPage } from '../pages/SKU';
import { SpotPage } from '../pages/SpotStrategy';
import { SuppliersPage } from '../pages/Suppliers';
import { UploadFilesPage } from '../pages/UploadsFiles';
import { UsersPage } from '../pages/Users';
import { ViewRFQ } from '../pages/ViewRFQ';
import { PrivateRoute } from './PrivateRoute';
import { RoutesPath } from './routesPath';

export function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={RoutesPath.login} exact component={SingleLogin} />
        <Route path={RoutesPath.resetPassord} exact component={ResetPasswordPage} />
        <Route path={RoutesPath.forgotPassword} exact component={ForgotPasswordPage} />
        <Route path={RoutesPath.redirectLogin} exact component={RedirectLogin} />
        <PrivateRoute path={RoutesPath.components} exact component={ComponentsPage} />
        <PrivateRoute path={RoutesPath.fileerrors} exact component={FileErrorsPage} />
        <PrivateRoute path={RoutesPath.logusers} exact component={LogUsersPage} />
        <PrivateRoute path={RoutesPath.scenarios} exact component={ScenariosPage} />
        <PrivateRoute path={RoutesPath.scenariosCreate} exact component={ScenariosForm} />
        <PrivateRoute path={RoutesPath.scenarios_Id_Year} exact component={ScenariosForm} />
        <PrivateRoute
          path={RoutesPath.scenariosSettings_Id_Year}
          exact
          component={ScenariosSettings}
        />
        <Redirect exact from={RoutesPath.reports} to={RoutesPath.reportsScenarios} />
        <PrivateRoute path={RoutesPath.reportsScenarios} exact component={ReportsScenariosPage} />
        <PrivateRoute path={RoutesPath.simulation} exact component={SimulationPage} />
        <PrivateRoute path={RoutesPath.forecasts} exact component={ForecastsPage} />
        <PrivateRoute path={RoutesPath.uploads} exact component={UploadFilesPage} />
        <PrivateRoute path={RoutesPath.suppliers} exact component={SuppliersPage} />
        <PrivateRoute path={RoutesPath.sku} exact component={SKUPage} />
        <PrivateRoute path={RoutesPath.ppb} exact component={PPBPage} />
        <PrivateRoute path={RoutesPath.ppb_Id} exact component={RulesPPBPage} />
        <PrivateRoute path={RoutesPath.prices} exact component={PricesPage} />
        <PrivateRoute path={RoutesPath.pricesTaxes} exact component={PricesTaxesPage} />
        <PrivateRoute path={RoutesPath.boms} exact component={BOMPage} />
        <PrivateRoute path={RoutesPath.users} exact component={UsersPage} />
        <PrivateRoute path={RoutesPath.groups} exact component={GroupsPage} />
        <PrivateRoute path={RoutesPath.avlc} exact component={AVLCPage} />
        <PrivateRoute path={RoutesPath.taxes} exact component={SettingsTaxes} />
        <PrivateRoute path={RoutesPath.quotes} exact component={QuotesPage} />
        <PrivateRoute path={RoutesPath.newForecast} exact component={NewForecastPage} />
        <PrivateRoute
          path={RoutesPath.dashboardSpecifications}
          exact
          component={SpecificationsDashboardPage}
        />
        <PrivateRoute path={RoutesPath.dashboardSKU} exact component={SKUDashboardPage} />
        <PrivateRoute path={RoutesPath.componentsgroup} exact component={ComponentsGroupPage} />
        <PrivateRoute path={RoutesPath.spot} exact component={SpotPage} />
        <PrivateRoute path={RoutesPath.alerts} exact component={AlertPage} />
        <PrivateRoute path={RoutesPath.alertsSKU_Id} exact component={SKUAlertPage} />
        <PrivateRoute path={RoutesPath.notifications} exact component={NotificationsPage} />
        <PrivateRoute path={RoutesPath.financeDashboard} exact component={FinanceDashboardPage} />
        <PrivateRoute
          path={RoutesPath.procurementDashboard}
          exact
          component={ProcurementDashboardPage}
        />
        <PrivateRoute path={RoutesPath.average} exact component={Average} />
        <PrivateRoute path={RoutesPath.finance} exact component={FinancePage} />
        <PrivateRoute path={RoutesPath.componentsPrices} exact component={ComponentsPricesPage} />
        <PrivateRoute path={RoutesPath.inventory} exact component={InventoryInOutPage} />
        <PrivateRoute path={RoutesPath.shareProportion} exact component={ShareProportion} />
        <PrivateRoute path={RoutesPath.newRFQ} exact component={NewRFQ} />
        <PrivateRoute path={RoutesPath.rfq} exact component={RfqPage} />
        <PrivateRoute path={RoutesPath.bidResponse} exact component={BidResponse} />
        <PrivateRoute
          path={RoutesPath.alertsQualification_id}
          exact
          component={QualificationAlert}
        />
        <PrivateRoute path={RoutesPath.viewRfq} exact component={ViewRFQ} />
        <PrivateRoute path={RoutesPath.bidSupplierView} exact component={BidSupplierView} />
        <PrivateRoute path={RoutesPath.awardSplit} exact component={AwardSplit} />
        <PrivateRoute path={RoutesPath.awardDetails} exact component={AwardDetails} />
        <PrivateRoute path={RoutesPath.awards} exact component={AwardsPage} />
      </Switch>
    </BrowserRouter>
  );
}
