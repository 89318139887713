import { useState } from 'react';
import { oneAlert } from '../../../../helpers/nUtils';
import { IParams } from '../types';
import { IData } from './types';

export function useTotalAwards() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalAwardsInformation, setTotalAwardsInformation] = useState<IData>();

  const fetchData = (filters: IParams) => {
    try {
      setIsLoading(true);
      setTotalAwardsInformation({
        impactSaving: 35451,
        impactSavingPercent: 4.5,
        totalDemands: 3454152,
        totalWinGross: 454545,
        totalWinNet: 545451,
      });
    } catch (err: any) {
      oneAlert({ type: 'error', message: err.message });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchData,
    isLoading,
    totalAwardsInformation,
  };
}
