import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledTab } from '../../components/Common/StyledTabs/types';
import { api } from '../../services/api';
import { services } from '../../services/services';

export function useAwardSummary() {
  const { id: bidId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [bidData, setBidData] = useState<{ name: string }>();
  const [tabs, setTabs] = useState<StyledTab[]>([
    { selected: true, title: t('pages.awards.tabs.simulation') },
    { selected: false, title: t('pages.awards.tabs.bidAllocation') },
    { selected: false, title: t('pages.awards.tabs.awardsList') },
    { selected: false, title: t('pages.awards.tabs.managementBid') },
  ]);

  const fetchBidInformations = async () => {
    try {
      const { data, status } = await api.get(`${services.rfq}/bid/admin/${bidId}`);
      if (status === 200) {
        setBidData({ name: data.overview.name });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (bidId) {
      fetchBidInformations();
    }
  }, []);

  return {
    bidData,
    tabs,
    setTabs,
  };
}
