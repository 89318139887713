import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const TitleContainer = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  span {
    font-size: 1.25rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;

export const Content = styled(Flex)`
  justify-content: center;
  padding: 1.5rem;
  gap: 1.5rem;
  flex-wrap: nowrap;
  @media (max-width: 1140px) {
    flex-wrap: wrap;
  }
`;

export const Card = styled.div`
  width: 19.5rem;
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const CardTitle = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const CardIconContent = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorsDesignSystem.backgroundIcon};
  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;

export const CardValue = styled.span`
  margin-left: 3.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const ImpactSavingPercent = styled.span`
  margin-left: 3.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const ImpactSavingValue = styled.span<{ value?: number }>`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme, value }) =>
    value
      ? value > 0
        ? theme.colorsDesignSystem.red_secondary
        : theme.colorsDesignSystem.rfqIndicationTagColors.greenFont
      : theme.colorsDesignSystem.inputLabelColor};
`;
