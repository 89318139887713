import { ReactNode, useRef, useState } from 'react';
import { t } from 'i18next';
import { PercentageOutlined } from '@ant-design/icons';
import { Flex, Input, InputNumber } from 'antd';
import * as S from './styles';
import {
  AwardSplitDistribution,
  AwardSplitPageProps,
  AwardSplitProps,
  AwardSupplierRanking,
  PayloadDistribution,
  PayloadToSend,
  setDistributionParams,
} from './types';
import { formatDataToSelect, handleFormatValueFields } from '../../helpers/nUtils';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { SupplierCard } from './SupplierCard';
import { SupplierCardProps } from './SupplierCard/types';
import { TotalAwardsData } from './ModalTotalAwards/types';
import { api } from '../../services/api';
import { getURI, oneAlert } from '../../helpers/utils';
import { services } from '../../services/services';
import theme from '../../styles/theme';

export function useAwardSplit() {
  const [dataAward, setDataAward] = useState<AwardSplitProps>();

  const [dataAwardModal, setDataAwardModal] = useState<TotalAwardsData>({
    altGroup: 'MM DDRIV 4GB',
    project: 'Calla_LC',
    totalWin: 609529.62,
    totalBaseline: 617259.03,
    totalFob: 939000.0,
    impactSaving: -7729.41,
    impactSavingPercentage: -22.7,
    suppliers: [
      {
        supplier: 'ZILIA',
        demand: 51000,
        impactSaving: -7350.0,
        impactSavingPercentage: -2.9,
        totalWin: 423800.0,
      },
      {
        supplier: 'A-DATA',
        demand: 22000,
        impactSaving: -378.8,
        impactSavingPercentage: -0.3,
        totalWin: 185600.0,
      },
      {
        supplier: 'HANA',
        demand: null,
        impactSaving: null,
        impactSavingPercentage: null,
        totalWin: null,
      },
    ],
  });

  // const initialDistribution = [
  //   {
  //     position: 1,
  //     percentage: 100,
  //     totalItens: dataAward?.totalDemand || 0,
  //   },
  //   {
  //     position: 2,
  //     percentage: 0,
  //     totalItens: 0,
  //   },
  //   {
  //     position: 3,
  //     percentage: 0,
  //     totalItens: 0,
  //   },
  // ];

  // const [awardDistribution, setAwardDistribution] =
  //   useState<Array<AwardSplitDistribution>>(initialDistribution);

  const [projects, setProjects] = useState<Array<StyledSelectOptions>>([]);

  const [suppliers, setSupplier] = useState<Array<StyledSelectOptions>>([]);

  const [suppliersSelected, setSuppliersSelected] = useState<Array<string>>();

  const [visualizationType, setVisualizationType] =
    useState<SupplierCardProps['visualizationType']>('NET');

  const [projectSelected, setProjectSelected] = useState('');
  const [errorDistribution, setErrorDistribution] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const propsPage = useRef<AwardSplitPageProps>();

  const handleLoadData = (data: AwardSplitProps) => {
    const projectsList = data?.projects || [];
    const supplierList = data?.suppliers || [];
    setProjects(formatDataToSelect(projectsList, false));
    setSupplier(formatDataToSelect(supplierList, false));
    setSuppliersSelected(data?.suppliers);
    setProjectSelected(projectsList[0]);
  };

  const handleGetAwards = async () => {
    try {
      setLoading(true);
      const { data, status } = await api.get(
        getURI(`${services.rfq}/award-simulation/${propsPage.current?.bidId}/split`, {
          altGroup: propsPage.current?.altGroup,
          project: projectSelected,
        })
      );
      if (status === 200) {
        setDataAward(data);
        handleLoadData(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetTotalAwardsModal = async () => {
    try {
      setLoading(true);
      if (projectSelected) {
        const { data, status } = await api.get(
          getURI(`${services.rfq}/award-simulation/${propsPage.current?.bidId}/total-awards`, {
            altGroup: propsPage.current?.altGroup,
            project: projectSelected,
          })
        );

        if (status === 200) {
          setDataAwardModal(data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllData = () => {
    Promise.all([handleGetAwards(), handleGetTotalAwardsModal()])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleRenderSpecs = () => {
    try {
      return dataAward?.specs.map((spec, index) => {
        return (
          <S.Labels className="normal" key={spec}>
            {index + 1 === dataAward.specs.length ? spec : `${spec} |`}
          </S.Labels>
        );
      });
    } catch (error) {
      return '-';
    }
  };

  const handleCalculatePercentProjectsDone = (): number => {
    try {
      return dataAward ? (dataAward.projectsConcluded / dataAward.totalProjects) * 100 : 0;
    } catch (error) {
      return 0;
    }
  };

  const handleVerifyIfSupplierIsSelectedOnSupplierSelect = (supplier: string): boolean => {
    return suppliersSelected
      ? suppliersSelected.some((supplierSelected) => supplierSelected === supplier)
      : false;
  };

  const handleToggleSplitDistribution = () => {
    setDataAward((prevState) => {
      if (!prevState) return prevState;
      return {
        ...prevState,
        splitAward: !prevState?.splitAward,
      };
    });
  };

  const handleRenderAwardSupplierCards = (): ReactNode => {
    try {
      return dataAward?.ranking.map((supplier) => {
        const projectIsSelected = handleVerifyIfSupplierIsSelectedOnSupplierSelect(
          supplier.supplier
        );
        return (
          <SupplierCard
            altGroup={dataAward.altGroup}
            type={projectIsSelected ? 'NORMAL' : 'EMPTY'}
            visualizationType={visualizationType}
            key={supplier.supplier}
            data={supplier}
            select={supplier.demand > 0}
          />
        );
      });
    } catch (error) {
      return null;
    }
  };

  const handleVerifyIfProjectIsNotFirst = (): boolean => {
    return projects.findIndex((project) => project.value === projectSelected) > 0;
  };

  const handleCalculatePercentageByQuantity = (quantity: number): number => {
    try {
      return dataAward
        ? Number(
            handleFormatValueFields({
              number: (quantity / dataAward.totalDemand) * 100,
              notation: 'standard',
            })
          )
        : 0;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleCalculateTotalItensByPercent = (percent: number): number => {
    try {
      return dataAward ? (percent / 100) * dataAward.totalDemand : 0;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleSetDistribution = ({ position, percent, quantity }: setDistributionParams) => {
    try {
      //limpando erro no formulário caso tenha sido exibido
      setErrorDistribution(false);

      if (quantity || quantity === 0) {
        setDataAward((prevState) => {
          if (!prevState) return prevState;
          return {
            ...prevState,
            ranking: prevState?.ranking.map((supplier) => {
              return supplier.position !== position
                ? supplier
                : {
                    ...supplier,
                    demand: Math.round(quantity),
                    demandPercentage: handleCalculatePercentageByQuantity(quantity),
                  };
            }),
          };
        });
      }

      if (percent || percent === 0) {
        setDataAward((prevState) => {
          if (!prevState) return prevState;
          return {
            ...prevState,
            ranking: prevState?.ranking.map((supplier) => {
              return supplier.position !== position
                ? supplier
                : {
                    ...supplier,
                    demand: handleCalculateTotalItensByPercent(percent),
                    demandPercentage: percent,
                  };
            }),
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearAwardDistribution = () => {
    setDataAward((prevState) => {
      if (!prevState) return prevState;
      return {
        ...prevState,
        ranking: prevState.ranking.map((supplier) => {
          return {
            ...supplier,
            demand: supplier.position === 1 ? prevState.totalDemand : 0,
            demandPercentage: supplier.position === 1 ? 100 : 0,
          };
        }),
      };
    });
  };

  const handleVerifyIfSupplierIsDisabled = (position: number): boolean => {
    const supplierByPosition = dataAward?.ranking.find(
      (supplier) => supplier.position === position
    );
    return suppliersSelected
      ? !suppliersSelected.includes(supplierByPosition?.supplier ?? '')
      : false;
  };

  const handleValidateAwardDistributionIsComplete = () => {
    try {
      debugger;
      const totalDistributed = dataAward?.ranking.reduce(
        (total, supplier) => total + supplier.demand,
        0
      );
      return totalDistributed === dataAward?.totalDemand;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleGenerateDistribution = (): Array<PayloadDistribution> => {
    try {
      const distribution: Array<PayloadDistribution> = [];
      dataAward?.ranking.forEach((supplier) => {
        if (supplier.demand > 0) {
          distribution.push({
            demand: supplier.demand,
            demandPercentage: supplier.demandPercentage,
            supplier: supplier.supplier,
          });
        }
      });
      return distribution;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleGeneratePayloadToSend = (): PayloadToSend => {
    return {
      altGroup: propsPage.current?.altGroup || '',
      project: projectSelected || '',
      splitAward: dataAward?.splitAward || false,
      distribution: dataAward?.splitAward ? handleGenerateDistribution() : null,
      remarks: dataAward?.remark || '',
    };
  };

  const handleSendAwardDistribution = async () => {
    try {
      if (handleValidateAwardDistributionIsComplete()) {
        const { data, status } = await api.post(
          `${services.rfq}/award-simulation/${propsPage.current?.bidId}/split`,
          handleGeneratePayloadToSend()
        );
      } else {
        setErrorDistribution(true);
      }
    } catch (error: any) {
      console.log(error);
      oneAlert('error', error.message || t('toast.errorOnSave'));
    } finally {
      setLoading(false);
    }
  };

  const handleRenderEditsSplitAward = (): ReactNode => {
    return dataAward?.ranking.map((supplier, index) => {
      //os suppliers são recebidos do back ordenados por posição logo índice + 1  =  posição no ranking
      const RANKING_POSITION = index + 1;
      return (
        <S.CardInputs key={supplier.position}>
          <S.ContainerInputs>
            <S.Labels className="small">{t('pages.awardSplit.labels.demand')}</S.Labels>
            <InputNumber
              style={{ width: '100%' }}
              max={dataAward.totalDemand}
              min={0}
              disabled={handleVerifyIfSupplierIsDisabled(RANKING_POSITION)}
              value={Math.round(supplier.demand)}
              onChange={(value) => {
                handleSetDistribution({
                  position: RANKING_POSITION,
                  quantity: Number(value) || 0,
                });
              }}
            />
          </S.ContainerInputs>
          <S.ContainerInputs>
            <InputNumber
              style={{ width: '100%' }}
              max={100}
              min={0}
              disabled={handleVerifyIfSupplierIsDisabled(RANKING_POSITION)}
              value={supplier.demandPercentage}
              onChange={(value) => {
                handleSetDistribution({
                  position: RANKING_POSITION,
                  percent: Number(value) || 0,
                });
              }}
              suffix={<PercentageOutlined style={{ color: theme.colorsDesignSystem.primary }} />}
            />
          </S.ContainerInputs>
        </S.CardInputs>
      );
    });
  };

  const handleSaveRemark = (remarkValue: string) => {
    setDataAward((prevState) => {
      if (!prevState) return prevState;
      return {
        ...prevState,
        remark: remarkValue,
      };
    });
  };

  return {
    handleRenderSpecs,
    handleCalculatePercentProjectsDone,
    handleRenderAwardSupplierCards,
    setVisualizationType,
    setProjectSelected,
    handleVerifyIfProjectIsNotFirst,
    setSuppliersSelected,
    handleSetDistribution,
    handleClearAwardDistribution,
    handleVerifyIfSupplierIsDisabled,
    handleValidateAwardDistributionIsComplete,
    setErrorDistribution,
    handleSendAwardDistribution,
    setVisibleModal,
    handleGetAwards,
    handleGetTotalAwardsModal,
    handleGetAllData,
    handleLoadData,
    handleToggleSplitDistribution,
    handleRenderEditsSplitAward,
    handleSaveRemark,
    dataAwardModal,
    errorDistribution,
    projectSelected,
    projects,
    suppliers,
    dataAward,
    suppliersSelected,
    visibleModal,
    loading,
    propsPage,
  };
}
