import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledSelectOptions } from '../../../components/Common/StyledSelect/types';
import { formatDataToSelect, oneAlert } from '../../../helpers/nUtils';
import { TagsContainer } from './styles';
import { IParams } from './types';

export function useManagementBid() {
  const { t } = useTranslation();
  const initialParams: IParams = {
    category: null,
    altGroup: null,
    supplier: null,
  };
  const [params, setParams] = useState<IParams>(initialParams);
  const [categories, setCategories] = useState<StyledSelectOptions[]>([]);
  const [altGroups, setAltGroups] = useState<StyledSelectOptions[]>([]);
  const [supplier, setSupplier] = useState<StyledSelectOptions[]>([]);
  const showTagsFilter = params.category || params.altGroup || params.supplier;

  const changeParam = (param: keyof IParams, value: string | null) => {
    setParams((prev) => {
      return { ...prev, [param]: value };
    });
  };

  const clearAllFilters = () => {
    setParams(initialParams);
  };

  const fetchCategories = async () => {
    try {
      setCategories(formatDataToSelect(['SSD', 'MEM'], true));
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    }
  };

  const fetchAltGroups = () => {
    try {
      setAltGroups(formatDataToSelect(['SSD 256', 'MEM 8GB'], true));
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    }
  };

  const fetchSupplier = () => {
    try {
      setSupplier(formatDataToSelect(['A-DATA', 'HANA'], true));
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    }
  };

  const handleParamsTags = () => {
    return (
      <TagsContainer>
        {showTagsFilter && <StyledClearTag onClick={clearAllFilters} />}
      </TagsContainer>
    );
  };

  useEffect(() => {
    setParams((prev) => {
      return { ...prev, altGroups: null, supplier: null };
    });
    Promise.all([fetchAltGroups(), fetchSupplier()]);
  }, [params.category]);

  useEffect(() => {
    setParams((prev) => {
      return { ...prev, supplier: null };
    });
    Promise.all([fetchSupplier()]);
  }, [params.altGroup]);

  useEffect(() => {
    Promise.all([fetchCategories(), fetchAltGroups(), fetchSupplier()]);
  }, []);

  return {
    params,
    categories,
    altGroups,
    supplier,
    changeParam,
    handleParamsTags,
  };
}
