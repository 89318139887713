import { ReactNode } from 'react';
import { Flex } from 'antd';
import { t } from 'i18next';
import { CheckCircleOutlined } from '@ant-design/icons';
import { PartNumbersCard, RankingCardProps } from '../types';
import * as S from './styles';
import theme from '../../../styles/theme';
import { handleFormatValueFields } from '../../../helpers/nUtils';
import { formatNumberToOrdinal } from '../../../helpers/FormatValues';

export function useCardAwards() {
  const handleRenderPartNumbers = (partNumbers: Array<PartNumbersCard>): ReactNode => {
    try {
      return partNumbers.map((partNumber) => {
        return (
          <>
            <Flex key={partNumber.partNumber} align="center" gap={8}>
              <S.Labels className="labelMedium">
                {t('pages.awardDetails.labels.partNumber')}
              </S.Labels>
              <Flex gap={4}>
                <CheckCircleOutlined
                  style={{ fontSize: '0.75rem', color: theme.colorsDesignSystem.primary }}
                />
                <S.Labels className="labelMedium">{partNumber.qstatus || '-'}</S.Labels>
              </Flex>
              <S.Labels bold className="labelMedium">
                {partNumber.partNumber}
              </S.Labels>
            </Flex>
            {/* <Flex align="center" gap={8}>
              <S.Labels className="labelMedium">{t('pages.awardDetails.labels.cm')}</S.Labels>
              <Flex gap={4}>
                <CheckCircleOutlined
                  style={{ fontSize: '0.75rem', color: theme.colorsDesignSystem.primary }}
                />
              </Flex>
              <S.Labels bold className="labelMedium">
                {partNumber.cm}
              </S.Labels>
            </Flex> */}
          </>
        );
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleRenderCardsInside = (cardsInside: Array<RankingCardProps>): ReactNode => {
    try {
      return cardsInside.map((card) => {
        return (
          <>
            <Flex vertical>
              <S.AwardCardInside>
                <S.AwardCardInsideLeft>
                  <Flex gap={50} style={{ marginBottom: '1.91rem' }}>
                    <Flex vertical gap={12}>
                      <S.Labels className="labelLarge">
                        {t('pages.awardDetails.labels.suppliers')}
                      </S.Labels>
                      <S.Labels className="title">{card.supplier || '-'}</S.Labels>
                    </Flex>
                    <S.CardPosition>
                      <S.Labels className="position">
                        {formatNumberToOrdinal(card.position) || '-'}
                      </S.Labels>
                    </S.CardPosition>
                  </Flex>
                  <Flex vertical gap={6}>
                    {handleRenderPartNumbers(card.partNumbers)}
                  </Flex>
                </S.AwardCardInsideLeft>
                <S.AwardCardInsideRight>
                  <Flex justify="space-between">
                    <S.Labels className="labelMedium">
                      {t('pages.awardDetails.labels.demand')}
                    </S.Labels>
                    <S.Labels className="labelValues">
                      {card?.demandPercentage
                        ? `${handleFormatValueFields({
                            number: card.demandPercentage,
                            notation: 'standard',
                          })}%`
                        : '-'}
                    </S.Labels>
                  </Flex>
                  <Flex justify="space-between">
                    <S.Labels className="labelSemiBold">
                      {t('pages.awardDetails.labels.totalDemand')}
                    </S.Labels>
                    <S.Labels className="totalSemiBold">
                      {card?.demand
                        ? `${handleFormatValueFields({
                            number: card.demand,
                            notation: 'standard',
                          })}`
                        : '-'}
                    </S.Labels>
                  </Flex>
                  <S.LineDashed />

                  <Flex justify="space-between">
                    <S.Labels className="labelMedium">
                      {t('pages.awardDetails.labels.grossPrice')}
                    </S.Labels>
                    <S.Labels className="labelValues">
                      {card?.grossPrice
                        ? `$ ${handleFormatValueFields({
                            number: card.grossPrice,
                            notation: 'standard',
                          })}`
                        : '-'}
                    </S.Labels>
                  </Flex>
                  <Flex justify="space-between">
                    <S.Labels className="labelMedium">
                      {t('pages.awardDetails.labels.totalGrossPrice')}
                    </S.Labels>
                    <S.Labels className="totalSemiBold">
                      {card?.totalGross
                        ? `$ ${handleFormatValueFields({
                            number: card.totalGross,
                            notation: 'compact',
                          })}`
                        : '-'}
                    </S.Labels>
                  </Flex>
                  <S.LineDashed />
                  <Flex justify="space-between">
                    <S.Labels className="labelSemiBold">
                      {t('pages.awardDetails.labels.totalNet')}
                    </S.Labels>
                    <S.Labels className="totalLarge">
                      {card?.totalNet
                        ? `$ ${handleFormatValueFields({
                            number: card.totalNet,
                            notation: 'compact',
                          })}`
                        : '-'}
                    </S.Labels>
                  </Flex>
                </S.AwardCardInsideRight>
              </S.AwardCardInside>
            </Flex>
            <S.ContainerFooter>
              <S.ContainerValuesFooter>
                <S.Labels className="labelSemiBold">
                  {t('pages.awardDetails.labels.impact')}
                </S.Labels>
                <Flex align="baseline" gap={8}>
                  <S.Labels className="labelMedium" bold>
                    {card?.impactSavingPercentage
                      ? `${handleFormatValueFields({
                          number: card.impactSavingPercentage,
                          notation: 'standard',
                        })}%`
                      : '-'}
                  </S.Labels>
                  <S.Labels className="labelColored">
                    {card?.impactSaving
                      ? `${handleFormatValueFields({
                          number: card.impactSaving,
                          notation: 'compact',
                        })}%`
                      : '-'}
                  </S.Labels>
                </Flex>
              </S.ContainerValuesFooter>
            </S.ContainerFooter>
          </>
        );
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return {
    handleRenderCardsInside,
  };
}
