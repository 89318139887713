import { Flex, Progress } from 'antd';
import { CheckCircleOutlined, ExclamationCircleFilled, TruckOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { t } from 'i18next';
import * as S from './styles';
import theme from '../../styles/theme';
import { useAwardDetails } from './useAwardDetails';
import ExpandableTable from '../ComponentsPrices/ExpandableTable';
import { CardsBidContainer } from '../BidResponse/styles';
import { CardSuppliers } from './CardBidSuplliers';
import { CardAwards } from './CardAwards';
import { handleFormatValueFields } from '../../helpers/nUtils';

export function AwardDetails() {
  const {
    handleRenderSpecs,
    handleFetchDetailsAward,
    handleRenderCardsSuppliers,
    setIsOpenAward,
    setIsOpenDetails,
    handleRenderAwardCards,
    handleCalculateProjectsConcludedPercent,
    isOpenCardAward,
    isOpenCardDetails,
    awardDetails,
  } = useAwardDetails();

  // const location = useLocation();
  // const { bidId, altGroup } = location.state as AwardSplitPageProps;

  useEffect(() => {
    handleFetchDetailsAward();
  }, []);

  return (
    <S.Container>
      <S.ContentLeft>
        <Flex
          align="center"
          gap={8}
          style={{ fontSize: '1.75rem', color: theme.colorsDesignSystem.tagColorYellow }}
        >
          <ExclamationCircleFilled />
          <S.TitleLeftCard>{awardDetails?.altGroup}</S.TitleLeftCard>
        </Flex>
        <S.Line />
        <Flex vertical gap={8} justify="center">
          <S.Labels className="withBorder">{`${awardDetails?.projectsConcluded}/${
            awardDetails?.totalProjects
          } ${t('pages.awardDetails.labels.projects')}`}</S.Labels>
          <Progress
            percent={handleCalculateProjectsConcludedPercent(
              awardDetails?.totalProjects || 0,
              awardDetails?.projectsConcluded || 0
            )}
            showInfo={false}
          />
        </Flex>
        <S.ContainerCards>
          <S.CardLeftSide>
            <S.Labels>{t('pages.awardDetails.labels.category')}</S.Labels>
            <S.Labels className="normal">{awardDetails?.category || '-'}</S.Labels>
          </S.CardLeftSide>
          <S.CardLeftSide>
            <S.Labels>{t('pages.awardDetails.labels.spec')}</S.Labels>
            <Flex gap={8}>{handleRenderSpecs()}</Flex>
          </S.CardLeftSide>
          <S.CardLeftSide>
            <S.CardFromTo>
              <S.Labels>{t('pages.awardDetails.labels.from')}</S.Labels>
              <S.Labels className="normal">{awardDetails?.from || '-'}</S.Labels>
              <TruckOutlined style={{ fontSize: '1.2rem', color: theme.colorsDesignSystem.blue }} />
              <S.Labels>{t('pages.awardDetails.labels.to')}</S.Labels>
              <S.Labels className="normal">{awardDetails?.to || '-'}</S.Labels>
            </S.CardFromTo>
          </S.CardLeftSide>
          <S.CardLeftSide>
            <Flex justify="space-between">
              <Flex vertical gap={4}>
                <S.Labels>{t('pages.awardDetails.labels.startingPrice').toUpperCase()}</S.Labels>
                <S.Labels className="large">
                  {awardDetails?.startingPrice
                    ? `$ ${handleFormatValueFields({
                        number: awardDetails.startingPrice,
                        notation: 'compact',
                      })}`
                    : '-'}
                </S.Labels>
              </Flex>
              |
              <Flex vertical gap={4}>
                <S.Labels>{t('pages.awardDetails.labels.startingPrice').toUpperCase()}</S.Labels>
                <S.Labels className="large">
                  {awardDetails?.totalDemand
                    ? `$ ${handleFormatValueFields({
                        number: awardDetails.totalDemand,
                        notation: 'compact',
                      })}`
                    : '-'}
                </S.Labels>
              </Flex>
            </Flex>
          </S.CardLeftSide>
        </S.ContainerCards>
      </S.ContentLeft>
      <S.ContainerRight>
        <S.Labels className="large">{t('pages.awardDetails.titles.titleRightCard')}</S.Labels>
        <S.CardsContainer>
          <S.HeaderExpandableItem>
            <ExpandableTable
              isOpen={isOpenCardDetails}
              onClick={() => {
                setIsOpenDetails(!isOpenCardDetails);
              }}
            />
            <Flex gap={8}>
              <span>{t('pages.awardDetails.labels.groupDetails')}</span>
              <span className="line">|</span>
              <span className="colored">{awardDetails?.altGroup || '-'}</span>
            </Flex>
          </S.HeaderExpandableItem>
          {isOpenCardDetails && (
            // {awardDetails?.groupDetail.isOpen && (
            <S.ContainerExpandableContent>
              <Flex align="center" gap={8}>
                <span className="subtitleBold">{t('pages.awardDetails.labels.total')}</span>
                <span className="subtitle">{t('pages.awardDetails.labels.netPrices')}</span>
                <S.Line className="noMargin" />
              </Flex>
              <S.GridCardsContainer>
                <S.CardNetPrices>
                  <S.Labels className="smallTitle">
                    {t('pages.awardDetails.labels.totalWin')}
                  </S.Labels>
                  <S.Labels className="subtltleBold">
                    {awardDetails?.groupDetail.totalWin
                      ? `$${handleFormatValueFields({
                          number: awardDetails.groupDetail.totalWin,
                          notation: 'compact',
                        })}`
                      : '-'}
                  </S.Labels>
                </S.CardNetPrices>
                <S.CardNetPrices>
                  <S.Labels className="smallTitle">
                    {t('pages.awardDetails.labels.totalBaseline')}
                  </S.Labels>
                  <S.Labels className="subtltleBold">
                    {awardDetails?.groupDetail.totalBaseline
                      ? `$${handleFormatValueFields({
                          number: awardDetails.groupDetail.totalBaseline,
                          notation: 'compact',
                        })}`
                      : '-'}
                  </S.Labels>
                </S.CardNetPrices>
                <S.CardNetPrices>
                  <S.Labels className="smallTitle">
                    {t('pages.awardDetails.labels.totalFob')}
                  </S.Labels>
                  <S.Labels className="subtltleBold">
                    {awardDetails?.groupDetail.totalFob
                      ? `$${handleFormatValueFields({
                          number: awardDetails.groupDetail.totalFob,
                          notation: 'compact',
                        })}`
                      : '-'}
                  </S.Labels>
                </S.CardNetPrices>
                <S.CardNetPrices>
                  <S.Labels className="smallTitle">
                    {t('pages.awardDetails.labels.impact')}
                  </S.Labels>
                  <Flex gap={8} align="center">
                    <S.Labels className="small">
                      {awardDetails?.groupDetail.impactSavingPercentage
                        ? `${handleFormatValueFields({
                            number: awardDetails.groupDetail.impactSavingPercentage,
                            notation: 'standard',
                          })}%`
                        : '-'}
                    </S.Labels>
                    |
                    <S.LabelColored>
                      {awardDetails?.groupDetail.impactSaving
                        ? `${handleFormatValueFields({
                            number: awardDetails.groupDetail.impactSaving,
                            notation: 'compact',
                          })}`
                        : '-'}
                    </S.LabelColored>
                  </Flex>
                </S.CardNetPrices>
              </S.GridCardsContainer>
              <Flex align="center" gap={8}>
                <span className="subtitleBold">{t('pages.awardDetails.labels.total')}</span>
                <span className="subtitle">{t('pages.awardDetails.labels.bidSupplier')}</span>
                <S.Line className="noMargin" />
              </Flex>
              <Flex vertical gap={16}>
                {handleRenderCardsSuppliers()}
              </Flex>
            </S.ContainerExpandableContent>
          )}
        </S.CardsContainer>
        <S.CardsContainer>
          <S.HeaderExpandableItem>
            <ExpandableTable
              isOpen={isOpenCardAward}
              onClick={() => {
                setIsOpenAward(!isOpenCardAward);
              }}
            />
            <Flex gap={8} align="center">
              <span>{t('pages.awardDetails.labels.award')}</span>
              <span className="subtitle">{t('pages.awardDetails.labels.card')}</span>
              <span className="line">|</span>
              <span className="colored">{awardDetails?.altGroup || '-'}</span>
            </Flex>
          </S.HeaderExpandableItem>
          {isOpenCardAward && handleRenderAwardCards()}
        </S.CardsContainer>
      </S.ContainerRight>
    </S.Container>
  );
}
