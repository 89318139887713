import { Flex } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import * as S from './styles';
import chat from '../../../assets/chat.svg';
import { SupplierCardProps } from './types';
import { useSupplierCard } from './useSupplierCard';
import theme from '../../../styles/theme';
import { handleFormatValueFields } from '../../../helpers/nUtils';
import empty from '../../../assets/empty.svg';
import { formatNumberToOrdinal } from '../../../helpers/FormatValues';
import StyledModal from '../../../components/Common/StyledModal';
import { CommentsModal } from '../../../components/Common/CommentsModal';

export function SupplierCard({
  data,
  visualizationType,
  type,
  select,
  altGroup,
}: SupplierCardProps) {
  const {
    handleRenderPartNumbers,
    handleValueIsPositive,
    setVisibleModal,
    handleConvertCommentAwardToComment,
    handleRenderFieldByCalculatedValue,
    visibleModal,
  } = useSupplierCard();

  return (
    <S.Container selected={select}>
      {type === 'NORMAL' && (
        <S.ContentContainer>
          <Flex justify="space-between" align="center">
            <S.Labels>{t('pages.awardSplit.awardCard.supplier')}</S.Labels>
            <S.PositionContainer>
              <span>{formatNumberToOrdinal(data.position)}</span>
            </S.PositionContainer>
          </Flex>
          <Flex justify="space-between" align="baseline">
            <S.Labels className="strong">{data.supplier || '-'}</S.Labels>
            <Flex gap={8} align="baseline">
              <S.Labels className="small">
                {data.variancePercentage
                  ? `${handleFormatValueFields({
                      number: data.variancePercentage,
                      notation: 'standard',
                    })}%`
                  : '-'}
              </S.Labels>
              <S.LabelColored color="red" size="normal">
                {data.variance
                  ? `$ ${handleFormatValueFields({ number: data.variance, notation: 'standard' })}`
                  : '-'}
              </S.LabelColored>
            </Flex>
          </Flex>
          {handleRenderPartNumbers(data.partNumbers)}
          <hr />
          <S.ContainerRows>
            <Flex vertical gap={8}>
              <S.Labels className="small">{t('pages.awardSplit.awardCard.capacity')}</S.Labels>
              <S.Labels className="middle">{data.capacity || '-'}</S.Labels>
            </Flex>
            <Flex vertical gap={8}>
              <S.Labels className="small">{t('pages.awardSplit.awardCard.leadTime')}</S.Labels>
              <S.Labels className="middle">
                {`${data.leadTime} ${t('pages.awardSplit.awardCard.days')}` || '-'}
              </S.Labels>
            </Flex>
            {data.comments && data.comments.length > 0 && (
              <S.ButtonIcon
                onClick={() => {
                  setVisibleModal(true);
                }}
              >
                <img src={chat} alt="chat" />
              </S.ButtonIcon>
            )}
            {(!data.comments || data.comments.length === 0) && (
              <MessageOutlined style={{ color: theme.colorsDesignSystem.primary }} />
            )}
          </S.ContainerRows>
          <hr />
          <S.ContainerNetPrices>
            {visualizationType === 'NET' && (
              <S.ContainerInsideNetGrossPrices hasMargin>
                <Flex vertical gap={40}>
                  <Flex justify="space-between" align="center">
                    <>
                      <S.Labels className="small">
                        {t('pages.awardSplit.awardCard.netPrice')}
                      </S.Labels>
                      <S.LabelColored color="blue" size="normal">
                        {data.netPrice
                          ? `$${handleFormatValueFields({
                              number: data.netPrice,
                              notation: 'standard',
                            })}`
                          : '-'}
                      </S.LabelColored>
                    </>
                  </Flex>
                  <Flex>
                    <S.ContainerHighLight>
                      <S.Labels className="smallBold">
                        {t('pages.awardSplit.awardCard.totalNet')}
                      </S.Labels>
                      <S.Labels className="middle">
                        {handleRenderFieldByCalculatedValue(data, 'totalNet')}
                      </S.Labels>
                    </S.ContainerHighLight>
                  </Flex>
                </Flex>
              </S.ContainerInsideNetGrossPrices>
            )}
            {visualizationType === 'GROSS' && (
              <S.ContainerGross>
                <Flex justify="space-between" align="center">
                  <S.Labels className="small">{t('pages.awardSplit.awardCard.lastGross')}</S.Labels>
                  <S.Labels className="normal">
                    {data.lastGross
                      ? `$${handleFormatValueFields({
                          number: data.lastGross,
                          notation: 'standard',
                        })}`
                      : '-'}
                  </S.Labels>
                </Flex>
                <Flex justify="space-between" align="center">
                  <S.Labels className="small">
                    {t('pages.awardSplit.awardCard.grossPrice')}
                  </S.Labels>
                  <S.LabelColored color="blue" size="normal">
                    {data.grossPrice
                      ? `$${handleFormatValueFields({
                          number: data.grossPrice,
                          notation: 'standard',
                        })}`
                      : '-'}
                  </S.LabelColored>
                </Flex>
                <S.ContainerHighLight>
                  <S.Labels className="small">{t('pages.awardSplit.awardCard.gapGross')}</S.Labels>
                  <S.ContainerGapGross>
                    <S.Labels className="small">
                      {data.gapGross
                        ? `$${handleFormatValueFields({
                            number: data.gapGross,
                            notation: 'compact',
                          })}%`
                        : '-'}
                    </S.Labels>
                    <S.LabelColored
                      size="normal"
                      color={handleValueIsPositive(data.impactSaving) ? 'red' : 'green'}
                    >
                      {data.gapGrossPercentage
                        ? `$${handleFormatValueFields({
                            number: data.gapGross,
                            notation: 'compact',
                          })}`
                        : '-'}
                    </S.LabelColored>
                  </S.ContainerGapGross>
                </S.ContainerHighLight>
                <S.ContainerHighLight>
                  <S.Labels className="smallBold">
                    {t('pages.awardSplit.awardCard.totalGross')}
                  </S.Labels>
                  <S.Labels className="normal">
                    {handleRenderFieldByCalculatedValue(data, 'totalGross')}
                  </S.Labels>
                </S.ContainerHighLight>
              </S.ContainerGross>
            )}
          </S.ContainerNetPrices>
          <hr />
          <S.ContainerBaseline>
            <Flex justify="space-between" align="center">
              <S.Labels className="small">{t('pages.awardSplit.awardCard.baseline')}</S.Labels>
              <S.Labels className="small">
                {data.baseline
                  ? `$ ${handleFormatValueFields({ number: data.baseline, notation: 'standard' })}`
                  : '-'}
              </S.Labels>
            </Flex>
            <Flex justify="space-between" align="center">
              <S.Labels className="smallBold">
                {t('pages.awardSplit.awardCard.totalBaseline')}
              </S.Labels>
              <S.Labels className="smallBold">
                {handleRenderFieldByCalculatedValue(data, 'totalBaseline')}
              </S.Labels>
            </Flex>
          </S.ContainerBaseline>
        </S.ContentContainer>
      )}
      {type === 'EMPTY' && (
        <S.ContentContainerEmpty>
          <img src={empty} alt="empty" />
          <p>No supplier select</p>
        </S.ContentContainerEmpty>
      )}
      <S.FooterContainer>
        {type === 'NORMAL' && (
          <>
            <S.Labels className="smallBold">
              {t('pages.awardSplit.awardCard.impactSaving')}
            </S.Labels>
            <Flex gap={6} align="center">
              {handleRenderFieldByCalculatedValue(data, 'impactSavingPercentage')}
              {handleRenderFieldByCalculatedValue(data, 'impactSaving')}
            </Flex>
          </>
        )}
        {type === 'EMPTY' && (
          <>
            <S.Labels className="smallBold">
              {t('pages.awardSplit.awardCard.impactSaving')}
            </S.Labels>
            <Flex gap={6} align="center">
              <S.Labels className="small">-</S.Labels>
              <S.LabelColored
                color={handleValueIsPositive(data.impactSaving) ? 'red' : 'green'}
                size="strong"
              >
                - `
              </S.LabelColored>
            </Flex>
          </>
        )}
      </S.FooterContainer>
      <StyledModal
        body={
          <CommentsModal
            altGroup={altGroup}
            supplier={data.supplier}
            comments={handleConvertCommentAwardToComment(data.comments || [], data)}
          />
        }
        footer={null}
        onClose={() => setVisibleModal(false)}
        title={t('pages.viewRfq.openCard.comments')}
        centered
        open={visibleModal}
        width="54rem"
      />
    </S.Container>
  );
}
