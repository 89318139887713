import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  width: 50.25rem;
  flex: 1;
`;

export const TitleContainer = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  b {
    font-size: 1.25rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  span {
    font-size: 1.25rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  }
`;

export const Content = styled(Flex)`
  justify-content: center;
  padding: 1.5rem;
`;
