import { DollarOutlined, FundOutlined, PieChartOutlined } from '@ant-design/icons';
import { Divider, Flex } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReceiptImg from '../../../../assets/receipt.svg';

import { Loading } from '../../../../components/Common/StyledLoading/styles';
import { handleFormatValueFields } from '../../../../helpers/nUtils';
import { IParams } from '../types';
import * as S from './styles';
import { useTotalAwards } from './useTotalAwards';

export function TotalAwards({ altGroup, category, supplier }: IParams) {
  const { t } = useTranslation();
  const { fetchData, isLoading, totalAwardsInformation } = useTotalAwards();

  useEffect(() => {
    fetchData({ altGroup, category, supplier });
  }, [altGroup, category, supplier]);

  return (
    <S.Container>
      <S.TitleContainer>
        <span>{t('pages.awards.managementBid.totalAwards.title')}</span>
      </S.TitleContainer>
      <S.Content>
        {isLoading ? (
          <Loading height={10} />
        ) : (
          <>
            {/* TOTAL DEMANDS */}
            <S.Card>
              <Flex gap={12} align="center">
                <S.CardIconContent>
                  <PieChartOutlined />
                </S.CardIconContent>
                <S.CardTitle>{t('pages.awards.managementBid.totalAwards.totalDemand')}</S.CardTitle>
              </Flex>
              <S.CardValue>
                {handleFormatValueFields({
                  number: totalAwardsInformation?.totalDemands,
                  maxFactional: 0,
                  minFractional: 0,
                })}
              </S.CardValue>
            </S.Card>

            {/* TOTAL WIN  NET */}
            <S.Card>
              <Flex gap={12} align="center">
                <S.CardIconContent>
                  <img src={ReceiptImg} alt="Recepit Icon" />
                </S.CardIconContent>
                <S.CardTitle>{t('pages.awards.managementBid.totalAwards.totalWinNet')}</S.CardTitle>
              </Flex>
              <S.CardValue>
                ${handleFormatValueFields({ number: totalAwardsInformation?.totalWinNet })}
              </S.CardValue>
            </S.Card>

            {/* TOTAL WIN  GRPSS */}
            <S.Card>
              <Flex gap={12} align="center">
                <S.CardIconContent>
                  <DollarOutlined />
                </S.CardIconContent>
                <S.CardTitle>
                  {t('pages.awards.managementBid.totalAwards.totalWinGross')}
                </S.CardTitle>
              </Flex>
              <S.CardValue>
                ${handleFormatValueFields({ number: totalAwardsInformation?.totalWinGross })}
              </S.CardValue>
            </S.Card>

            {/* IMPACT/SAVING*/}
            <S.Card>
              <Flex gap={12} align="center">
                <S.CardIconContent>
                  <FundOutlined />
                </S.CardIconContent>
                <S.CardTitle>
                  {t('pages.awards.managementBid.totalAwards.impactSavingEstimate')}
                </S.CardTitle>
              </Flex>
              <S.ImpactSavingPercent>
                {totalAwardsInformation?.impactSavingPercent}%
              </S.ImpactSavingPercent>
              <Divider type="vertical" />
              <S.ImpactSavingValue value={totalAwardsInformation?.impactSaving}>
                ${handleFormatValueFields({ number: totalAwardsInformation?.impactSaving })}
              </S.ImpactSavingValue>
            </S.Card>
          </>
        )}
      </S.Content>
    </S.Container>
  );
}
