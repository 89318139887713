import { useState } from 'react';
import { SupplierTotalAwards } from './types';
import { CardAwardBidSupplier } from '../CardAwardBidSupplier';
import { handleFormatValueFields } from '../../../helpers/nUtils';

export function useModalTotalAwards() {
  const [openCard, setOpenCard] = useState(true);

  const handleRenderCardsBidSupplier = (suppliers: Array<SupplierTotalAwards>) => {
    return suppliers.map((supplier) => (
      <CardAwardBidSupplier
        key={supplier.demand}
        demand={
          supplier.demand
            ? handleFormatValueFields({ number: supplier.demand, notation: 'standard' })
            : '-'
        }
        impact={
          supplier.impactSavingPercentage
            ? `${handleFormatValueFields({
                number: supplier.impactSavingPercentage,
                notation: 'standard',
              })}%`
            : '-'
        }
        saving={
          supplier.impactSaving
            ? `${handleFormatValueFields({ number: supplier.impactSaving, notation: 'compact' })}`
            : '-'
        }
        supplier={supplier.supplier}
        totalWin={
          supplier.totalWin
            ? `${handleFormatValueFields({ number: supplier.totalWin, notation: 'compact' })}`
            : '-'
        }
      />
    ));
  };

  const handleValueIsPositive = (value: number) => {
    try {
      return !Number.isNaN(value) && value > 0;
    } catch (error) {
      return false;
    }
  };

  return {
    openCard,
    setOpenCard,
    handleValueIsPositive,
    handleRenderCardsBidSupplier,
  };
}
