// eslint-disable-next-line no-shadow
export enum RoutesPath {
  login = '/',
  resetPassord = '/reset-password',
  forgotPassword = '/forgot-password',
  components = '/components',
  fileerrors = '/fileerrors',
  logusers = '/logusers',
  scenarios = '/scenarios',
  scenariosCreate = '/scenarios/create',
  scenarios_Id_Year = '/scenarios/:id/:year',
  scenariosSettings = '/scenarios/settings',
  scenariosSettings_Id_Year = '/scenarios/settings/:id/:year',
  reports = '/reports',
  reportsScenarios = '/reports/scenarios',
  simulation = '/simulation',
  forecasts = '/demands',
  uploads = '/Uploads',
  suppliers = '/suppliers',
  sku = '/sku',
  ppb = '/ppb',
  ppb_Id = '/ppb/:id',
  prices = '/prices',
  pricesTaxes = '/prices-taxes',
  boms = '/boms',
  users = '/users',
  groups = '/groups',
  avlc = '/avlc',
  taxes = '/taxes',
  quotes = '/quotes',
  dashboardSpecifications = '/dashboard/specifications',
  dashboardSKU = '/dashboard/sku',
  componentsgroup = '/components-group',
  spot = '/spot',
  alerts = '/alerts',
  alertsSKU = '/alerts/sku',
  alertsSKU_Id = '/alerts/sku/:id',
  notifications = '/notifications',
  procurementDashboard = '/dashboard/procurement',
  financeDashboard = '/dashboard/finance',
  average = '/average',
  newForecast = '/new-demand',
  componentsPrices = '/components-prices',
  finance = '/finance',
  inventory = '/inventory',
  shareProportion = '/share-proportion',
  newRFQ = '/new-rfq',
  rfq = '/rfq',
  bidResponse = '/bid-response',
  redirectLogin = '/redirect-login',
  alertsQualification = '/alerts/qualification',
  alertsQualification_id = '/alerts/qualification/:id',
  viewRfq = '/view-rfq',
  bidSupplierView = '/bid-supplier-view',
  awardSplit = '/award-split',
  awardDetails = '/award-details',
  awards = '/awards/:id',
}
