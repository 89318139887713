import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 0.063rem solid;
  border-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.borderColor};
  border-radius: 0.375rem;
  margin-top: 1rem;

  span {
    font-size: 1.125rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colorsDesignSystem.text};

    &.line {
      font-weight: 400;
      color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    }

    &.subtitleBold {
      font-size: 1.25rem;
      font-weight: bold;
    }

    &.subtitle {
      font-size: 1.25rem;
      font-weight: 300;
    }

    &.smallTitle {
      color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
`;

export const ContainerExpandableContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const HeaderExpandableItem = styled.div`
  padding: 1.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
`;

export const Line = styled.hr`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  &.noMargin {
    flex: 1;
  }
`;

export const GridCardsNetPrices = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
`;

export const CardNetPrices = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
  height: 5rem;
  min-width: 12rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const Labels = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};

  &.withBorder {
    margin-top: 0.5rem;
  }
  &.normal {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.large {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;

export const LabelColored = styled.p<{
  color: 'red' | 'green';
  size: 'normal' | 'strong';
}>`
  margin: 0;
  font-size: ${({ size }) => (size === 'normal' ? '0.875rem' : '1.25rem')};
  color: ${({ color, theme }) =>
    color === 'red'
      ? theme.colorsDesignSystem.red
      : theme.colorsDesignSystem.rfqIndicationTagColors.greenFont};
`;
