import { Flex } from 'antd';
import { t } from 'i18next';
import ExpandableTable from '../../ComponentsPrices/ExpandableTable';
import * as S from './styles';
import { useModalTotalAwards } from './useModalTotalAwards';
import { ModalAwardProps } from './types';
import { handleFormatValueFields } from '../../../helpers/nUtils';

export function ModalTotalAwards({ data }: ModalAwardProps) {
  const { openCard, setOpenCard, handleValueIsPositive, handleRenderCardsBidSupplier } =
    useModalTotalAwards();

  return (
    <S.Container>
      <Flex vertical>
        <S.HeaderExpandableItem>
          <ExpandableTable
            isOpen
            onClick={() => {
              setOpenCard(!openCard);
            }}
          />
          <Flex gap={8}>
            <span>{t('pages.awardSplit.modal.labels.titleCard')}</span>
            <span className="line">|</span>
            <span className="colored">{data.altGroup}</span>
            <span className="line">|</span>
            <span className="subtitle">{data.project}</span>
          </Flex>
        </S.HeaderExpandableItem>
        {openCard && (
          <S.ContainerExpandableContent>
            <Flex align="center" gap={8}>
              <span className="subtitleBold">{t('pages.awardSplit.modal.total')}</span>
              <span className="subtitle">{t('pages.awardSplit.modal.netPrices')}</span>
              <S.Line className="noMargin" />
            </Flex>
            <S.GridCardsNetPrices>
              <S.CardNetPrices>
                <S.Labels className="smallTitle">{t('pages.awardSplit.modal.totalWin')}</S.Labels>
                <S.Labels className="subtltleBold">
                  {data.totalWin || data.totalWin === 0
                    ? `$ ${handleFormatValueFields({
                        number: data.totalWin,
                        notation: 'standard',
                      })}`
                    : '-'}
                </S.Labels>
              </S.CardNetPrices>
              <S.CardNetPrices>
                <S.Labels className="smallTitle">
                  {t('pages.awardSplit.modal.totalBaseline')}
                </S.Labels>
                <S.Labels className="subtltleBold">
                  {data.totalBaseline || data.totalBaseline === 0
                    ? `$${handleFormatValueFields({
                        number: data.totalBaseline,
                        notation: 'standard',
                      })}`
                    : '-'}
                </S.Labels>
              </S.CardNetPrices>
              <S.CardNetPrices>
                <S.Labels className="smallTitle">{t('pages.awardSplit.modal.totalFob')}</S.Labels>
                <S.Labels className="subtltleBold">
                  {data.totalFob || data.totalFob === 0
                    ? `$ ${handleFormatValueFields({
                        number: data.totalFob,
                        notation: 'standard',
                      })}`
                    : '-'}
                </S.Labels>
              </S.CardNetPrices>
              <S.CardNetPrices>
                <S.Labels className="smallTitle">
                  {t('pages.awardSplit.modal.impactSaving')}
                </S.Labels>
                <Flex gap={6} align="center">
                  <S.Labels className="smallTitle">
                    {data.impactSavingPercentage || data.impactSavingPercentage === 0
                      ? `${handleFormatValueFields({
                          number: data.impactSavingPercentage,
                          notation: 'standard',
                        })}%`
                      : '-'}
                  </S.Labels>
                  <S.Labels className="smallTitle">|</S.Labels>
                  <S.LabelColored
                    size="strong"
                    color={handleValueIsPositive(data.impactSaving) ? 'red' : 'green'}
                  >
                    {data.impactSaving || data.impactSaving === 0
                      ? `$${handleFormatValueFields({
                          number: data.impactSaving,
                          notation: 'standard',
                        })}`
                      : '-'}
                  </S.LabelColored>
                </Flex>
              </S.CardNetPrices>
            </S.GridCardsNetPrices>
            <Flex align="center" gap={8}>
              <span className="subtitleBold">{t('pages.awardSplit.modal.total')}</span>
              <span className="subtitleBold">{t('pages.awardSplit.modal.total')}</span>
              <span className="subtitle">{t('pages.awardSplit.modal.bidSupplier')}</span>
              <S.Line className="noMargin" />
            </Flex>
            <Flex vertical gap={16}>
              {handleRenderCardsBidSupplier(data.suppliers)}
            </Flex>
          </S.ContainerExpandableContent>
        )}
      </Flex>
    </S.Container>
  );
}
