import { Flex } from 'antd';
import { t } from 'i18next';
import { bidSuppliers } from './types';
import * as S from './styles';

export function CardAwardBidSupplier({ demand, impact, saving, supplier, totalWin }: bidSuppliers) {
  return (
    <S.Container>
      <S.ContainerSupplier>
        <S.Labels className="title">{supplier}</S.Labels>
      </S.ContainerSupplier>
      <S.ContainerData>
        <S.Content>
          <S.Labels>{t('components.cardAwardBidSupplier.labels.impact')}</S.Labels>
          <Flex gap={8} align="center">
            <S.Labels>{impact}</S.Labels>
            <S.Labels className="pipe">|</S.Labels>
            <S.Labels className="colored">{saving}</S.Labels>
          </Flex>
        </S.Content>
        <S.Content>
          <S.Labels>{t('components.cardAwardBidSupplier.labels.demand')}</S.Labels>
          <Flex gap={8} align="center">
            <S.Labels className="value">{demand}</S.Labels>
          </Flex>
        </S.Content>
        <S.Content>
          <S.Labels>{t('components.cardAwardBidSupplier.labels.total')}</S.Labels>
          <Flex gap={8} align="center">
            <S.Labels className="valueLarge">{saving}</S.Labels>
          </Flex>
        </S.Content>
      </S.ContainerData>
    </S.Container>
  );
}
