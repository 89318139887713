import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../components/Common/StyledButton';
import StyledSelect from '../../../components/Common/StyledSelect';
import { DemandsWin } from './DemandWin';
import { ImpactSavingWin } from './ImpactSavingWin';
import { PricesWin } from './PricesWin';
import * as S from './styles';
import { TotalAwards } from './TotalAwards';
import { useManagementBid } from './useManagementBid';

export function ManagementBID() {
  const { t } = useTranslation();
  const { altGroups, categories, params, supplier, changeParam, handleParamsTags } =
    useManagementBid();
  return (
    <Flex vertical>
      <S.FiltersContainer>
        <StyledSelect
          onChange={(value: string) => changeParam('category', value)}
          label={t('pages.awards.managementBid.filters.category')}
          placeholder={t('pages.awards.managementBid.filters.placeholder')}
          styles={{ width: '7.5rem' }}
          value={params.category}
          options={categories}
          showSearch
        />
        <StyledSelect
          onChange={(value: string) => changeParam('altGroup', value)}
          label={t('pages.awards.managementBid.filters.altGroup')}
          placeholder={t('pages.awards.managementBid.filters.placeholder')}
          styles={{ width: '7.5rem' }}
          value={params.altGroup}
          options={altGroups}
          showSearch
        />
        <StyledSelect
          onChange={(value: string) => changeParam('supplier', value)}
          label={t('pages.awards.managementBid.filters.supplier')}
          placeholder={t('pages.awards.managementBid.filters.placeholder')}
          styles={{ width: '7.5rem' }}
          value={params.supplier}
          options={supplier}
          showSearch
        />
        <StyledButton variant="primary">{t('common.apply')}</StyledButton>
      </S.FiltersContainer>
      <Flex>{handleParamsTags()}</Flex>
      <Flex vertical gap={24}>
        <TotalAwards
          altGroup={params.altGroup}
          category={params.category}
          supplier={params.supplier}
        />
        <Flex gap={24}>
          <PricesWin {...params} />
          <DemandsWin {...params} />
        </Flex>
        <ImpactSavingWin {...params} />
      </Flex>
    </Flex>
  );
}
